import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from '@mui/material/CircularProgress';
import { useListIacMilestonesQuery } from "../../../generated";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useDownloadReportIac } from "../../../hooks/useDownloadReportsIac";

interface DownloadReportsProps {
  wafrArn: string;
  workloadname: string;
  workloadid: string;
  workloadlens: string;
}

export default function DownloadIacReports({wafrArn, workloadname, workloadid, workloadlens}: DownloadReportsProps){
  const {downloadReport, loading } = useDownloadReportIac(wafrArn);
  const [activeSpinner, setActiveSpinner] = useState(false);

  const milestones = useListIacMilestonesQuery({
    variables: {
      workloadid: workloadid,
      awsWafrAccount: wafrArn
    },
    context: {
      apiName: "well_architected"
    },
    notifyOnNetworkStatusChange: true,
  });
  let milestonenumber = 0;

  async function Download(){
    milestones.data?.listIacMilestones?.MilestoneSummaries.map((value: any, index) => {
      if(index < 1){
        milestonenumber = value.MilestoneNumber;
      }
      return (
        <></>
      );
    })
    setActiveSpinner(true);
    await Promise.all([
      downloadReport(
        workloadname,
        workloadid,
        workloadlens,
        milestonenumber
      )
    ]);
    setActiveSpinner(false);
  }


  return (
    <IconButton disabled={loading} onClick={() => Download()} type="submit" color="secondary">
      {activeSpinner ? <CircularProgress /> : <CloudDownloadOutlinedIcon />}
    </IconButton>
  );
}
