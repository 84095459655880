import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from '@mui/material/CircularProgress';
import { useListMilestonesQuery } from "../../../generated";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useDownloadReport } from "../../../hooks/useDownloadReport";

interface DownloadReportsProps {
  arn: string;
  workloadname: string;
  workloadid: string;
  workloadlens: string;
}

export default function DownloadReports({arn, workloadname, workloadid, workloadlens}: DownloadReportsProps){
  const { downloadReport, loading } = useDownloadReport(arn);
  const [activeSpinner, setActiveSpinner] = useState(loading);
  

  const milestones = useListMilestonesQuery({
    variables: {
      arn: arn,
      workloadid: workloadid
    },
    context: {
      apiName: "well_architected"
    },
    notifyOnNetworkStatusChange: true,
  });
  let milestonenumber = 0;

  async function Download(){
    milestones.data?.listMilestones?.MilestoneSummaries.map((value: any, index) => {
      if(index < 1){
        milestonenumber = value.MilestoneNumber;
      }
      return (
        <></>
      );
    })
    setActiveSpinner(true);
    await Promise.all([
      downloadReport(
        workloadname,
        workloadid,
        workloadlens,
        milestonenumber
      ),
    ]);
    setActiveSpinner(false);
  }
  
  return (
    <IconButton disabled={loading} onClick={() => Download()} type="submit" color="secondary">
      {activeSpinner ? <CircularProgress /> : <CloudDownloadOutlinedIcon />}
    </IconButton>
  );
}
