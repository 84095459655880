import { useDownloadReportsLazyQuery, OnResultDocument } from "../generated";
import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import useSnackBars from "./useSnackbar";

const useDownloadReport = (arn: string) => {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const { setAlerts } = useSnackBars();

  const [ downloadReportQuery ] = useDownloadReportsLazyQuery({});

  const onDownloadWATClick = (url:string, filename:string) => {
    const watelement = document.createElement("a");
    watelement.href = url;
    watelement.download = filename;
    document.body.appendChild(watelement);
    watelement.click();
    document.body.removeChild(watelement);
  }

  const downloadReport = async (
    workloadName: string,
    workloadId: string,
    workloadLens: string,
    milestoneNumber: number | undefined | null
  ) => {
    setLoading(true);
    const reportdownload = await downloadReportQuery({
      variables: {
        arn: arn,
        workloadname: workloadName,
        workloadid: workloadId,
        lens: workloadLens,
        milestonenumber: milestoneNumber,
      },
      context: {
        apiName: "well_architected",
      },
    }).then(async (data) => {
      const subscriptionPromise = await new Promise(async (resolve, reject) => {
        client
        ?.subscribe({
          query: OnResultDocument,
          variables: {
            id: data?.data?.downloadReports?.id,
          },
        })
        .subscribe({
          next: ({ data }: any) => {
            if(data?.onResult?.status === "COMPLETE"){
              let decoded = JSON.parse(data?.onResult?.result);
              onDownloadWATClick(decoded["waturl"], decoded["watfilename"])
            } else if(data?.onResult?.status === "ERROR"){
              setAlerts([
                {
                  severity: "error",
                  msg: "We're experiencing an issue with AWS. Please wait one minute and try again.",
                },
              ]);
            }
            setLoading(false);
            resolve(
							true
						);
          },
          error: () => {
            setLoading(false);
            reject(
							new Error("We're experiencing an issue with AWS. Please wait one minute and try again."),
						); 
          },
        });
      });
      return subscriptionPromise;
    });
    return {reportdownload}
  };

  return {
    downloadReport,
    loading,
  };
};

export { useDownloadReport };
