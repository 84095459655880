import React, { useState, FC, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Checkbox from "@mui/material/Checkbox";
import CardHeader from "@mui/material/CardHeader";
import {
  CardMedia,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import workloadimage from "./../../../assets/6pillars_wat_graphic_new_color.png";
import { useCreateWorkload } from "../../../hooks/useCreateWorkload";
import useAccount from "../../../hooks/useAccount";
import { useListSecurityControlsQuery } from "../../../hooks/useListSecurityControlsQuery";
import ConnectedAccount from "../../../models/ConnectedAccount";
import LoadingButton from "@mui/lab/LoadingButton";
import { useUpdateOpportunityMutation } from "../../../generated";

interface CreateWorkloadProps {
  arn: string;
  region: string;
  organisation: any;
  setCreatedWorkloads: any;
  setRiskCountsDict: any;
  setFirstRiskCountsDict: any;
  changeTab: (
    index: number,
    targetArn: string,
    connectedAccounts: Array<ConnectedAccount>
  ) => void;
  setUnlockStep2: (index: boolean) => void;
  setUnlockStep3: (index: boolean) => void;
}

interface WorkloadsDictionary {
  [key: string]: string;
}

interface RiskCountDictionary {
  [key: string]: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const accountIdRegExp = /[0-9]{12}/;

const validationSchema = yup.object().shape({
  awsCreditAccount: yup
    .string()
    .matches(accountIdRegExp, "AWS Credit Account Id is not valid")
    .required("AWS Credit Account Id is required"),
  ...Array.from({ length: 4 }, (_, index) => index + 1).reduce<Record<string, yup.StringSchema>>(
    (acc, num) => {
      acc[`textField${num}`] = yup
        .string()
        .min(3, "Workload Name should be at least 3 characters long.")
        .test("unique", "Workload names must be unique", function (value) {
          if (!value) return true; // Skip validation for empty fields
          const allValues = Object.values(this.parent as Record<string, string>).slice(
            1,
            (this.parent as any).numberOfWorkloads + 1
          );
          return allValues.filter((v) => v === value).length <= 1;
        });

      return acc;
    },
    {}
  ),
  ...Array.from({ length: 4 }, (_, index) => index + 1).reduce<Record<string, yup.StringSchema<string | undefined>>>(
    (acc, num) => {
      acc[`opportunityIdentifier${num}`] = yup
        .string()
        .optional() // Makes the field optional
        .test("unique", "Opportunity identifiers must be unique", function (value) {
          if (!value) return true; // Skip validation for empty fields
          const allValues = Object.values(this.parent as Record<string, string | undefined>)
            .filter((v): v is string => !!v); // Exclude undefined values

          return allValues.filter((v) => v === value).length <= 1;
        });

      return acc;
    },
    {}
  ),
});

const CreateWorkload: FC<CreateWorkloadProps> = ({
  arn,
  region,
  organisation,
  setCreatedWorkloads,
  setRiskCountsDict,
  setFirstRiskCountsDict,
  changeTab,
  setUnlockStep2,
  setUnlockStep3,
}: CreateWorkloadProps) => {
  const { accounts, connectedAccounts } = useAccount();
  const creditaccount = accounts.find((i: any) => i.arn === arn);

  //useCreateWorklod hook
  const { createWorkload } = useCreateWorkload({
    arn,
    region,
  });

  const [updateOpportunityMutation] = useUpdateOpportunityMutation();

  //Used for the Modal that is displayed after workloads have been set to be created
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [disabled, setDisabled] = useState(false);
  const [complete, setComplete] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false); // spinner that displays while workloads are being created
  const [workloadError, setWorkloadError] = useState(false);
  const [connectedAutomatePlus, setConnectedAutomatePlus] = useState(true);
  const [controlsUnknownAlert, setControlsUnkonwnAlert] = useState(false);

  const [unknownPercentage, setUnknownPecentage] = useState(0); //Used to display an error alert if large number of controls are in unknown state
  const [statusError, setStatusError] = useState(""); //Display error message from createworkload api

  const [technicalReviewArn, setTechnicalReviewArn] = useState(arn); //track technical review account that is selected
  const [connectedAccountsDropDown, setConnectedAccountsDropDown] =
    useState(connectedAccounts); //Displays the connected accounts dropdown
  const [accountIdRef, setAccountIdRef] = useState<string>("");
  const [targetAccountType, setTargetAccountType] = useState(
    creditaccount?.accounttype
  );

  //Technical Review Account Dropdown Handle
  const handleTechnicalReviewAccount = (e: any) => {
    const account = accounts.find((i: any) => i.arn === e.target.value);
    if (account) {
      setConnectedAccountsDropDown(account.connectedAccounts);
      setAccountIdRef(account.connectedAccounts?.[0]?.accountIdRef);
      setTechnicalReviewArn(account.arn);
      setTargetAccountType(account.accounttype);
    }
  };

  //Connected Account Dropdown Handle
  const handleConnectedAccount = (e: any) => {
    setAccountIdRef(e.target.value as string);
    const account = accounts.find((i: any) => i.arn === technicalReviewArn); //get the account that the connectedAccount belongs to
    if (account) {
      let automatePlus = false;
      //Check if connected account is part of the member_accounts or is the same as targetAccount
      if (
        account?.member_accounts.includes(e.target.value as string) ||
        technicalReviewArn.split(":")[4] === (e.target.value as string)
      ) {
        automatePlus = true;
      } else {
        automatePlus = false;
      }
      //If connected Account is part of member account or if the targetAccount and connected account
      //are the same then set true to connectedAutomatePlus
      setConnectedAutomatePlus(automatePlus);
    }
  };

  const getFilteredConnectedAccounts = () => {
    return connectedAccountsDropDown
      .filter((connectedAccount: ConnectedAccount) => {
        return connectedAccount.accountIdRef === accountIdRef;
      })
      .map((connectedAccount: ConnectedAccount) => {
        return {
          accountIdRef: connectedAccount.accountIdRef,
          region: connectedAccount.region,
        };
      });
  };

  const { controls, loading } = useListSecurityControlsQuery({
    arn: technicalReviewArn,
    connectedAccounts: getFilteredConnectedAccounts(),
  });

  //Update getFilteredConnectedAccounts() when accountIdRef changes
  useEffect(() => {
    getFilteredConnectedAccounts();
  }, [accountIdRef]);

  //If the Account where a workload to be created is a LITE account type then disable create button
  useEffect(() => {
    const account = accounts.find((i: any) => i.arn === arn);
    if(account?.accounttype === "LITE"){
      setDisabled(true);
    }
  }, [arn]);
  

  //Set Alert if large percentage of control status is in an unknown state
  useEffect(() => {
    let data: any = controls.filter((control: any) => {
      if (
        Array.isArray(control?.customStandard) &&
        control?.compliancestatus === "UNKNOWN" &&
        control?.customStandard.length > 0 &&
        control?.customStandard.some((standard: string) => {
          return standard.includes("AWS Well Architected Framework");
        })
      ) {
        return true;
      }
      return false;
    });

    let totalDataLength: any = controls.filter((control: any) => {
      if (
        Array.isArray(control?.customStandard) &&
        control?.customStandard.length > 0 &&
        control?.customStandard.some((standard: string) => {
          return standard.includes("AWS Well Architected Framework");
        })
      ) {
        return true;
      }
      return false;
    });

    if (!loading && data) {
      let percentage = Math.round((data.length / totalDataLength.length) * 100);
      setUnknownPecentage(percentage);
      if (percentage >= 30) {
        setControlsUnkonwnAlert(true);
      }
    }
  }, [controls, loading]); // Add Dicts as a dependencyy

  const addWorkloadKeyValuePairs = (newPairs: WorkloadsDictionary) => {
    setCreatedWorkloads((createdWorkloads: any) => ({
      ...createdWorkloads,
      ...newPairs,
    }));
  };

  const addRiskKeyValuePairs = (newPairs: RiskCountDictionary) => {
    setRiskCountsDict((riskCountsDict: any) => ({
      ...riskCountsDict,
      ...newPairs,
    }));
    setFirstRiskCountsDict((firstRiskCountsDict: any) => ({
      ...firstRiskCountsDict,
      ...newPairs,
    }));
  };

  const formik = useFormik({
    initialValues: {
      numberOfWorkloads: 1, // Initial value for number of text fields
      // Initial values for dynamic text fields
      ...new Array(4).fill("").reduce((acc, _, index) => {
        acc[`textField${index + 1}`] = "";
        return acc;
      }, {}),
      ...new Array(4).fill("").reduce((acc, _, index) => {
        acc[`opportunityIdentifier${index + 1}`] = "";
        return acc;
      }, {}),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //Clear any previous states that might have been passed
      setCreatedWorkloads({});
      setRiskCountsDict({});
      setFirstRiskCountsDict({});
      //continue with rest of the process
      setUnlockStep3(false);
      handleOpen();
      setDisabled(true);
      setActiveSpinner(true);
      setComplete(false);
      let failed = false;
      for (let i = 0; i < values.numberOfWorkloads; i++) {
        let workloadname = organisation + "-" + values[`textField${i + 1}`];
        let opportunityidentifier = values[`opportunityIdentifier${i + 1}`];
        const response = await Promise.all([
          createWorkload(
            workloadname,
            technicalReviewArn,
            getFilteredConnectedAccounts(),
            accountIdRef,
            values.awsCreditAccount
          ),
        ]);
        if (
          response[0]?.workloadcreation?.data?.createWorkload?.Status !==
          "SUCCESS"
        ) {
          setWorkloadError(true);
          failed = true;
          setStatusError(
            response[0]?.workloadcreation?.data?.createWorkload?.Status || ""
          );
        } else {
          if (opportunityidentifier !== "") {
            const opportunityResponse = await updateOpportunityMutation({
              context: {
                apiName: "well_architected"
              },
              variables: {
                arn,
                projectTitle: workloadname,
                opportunityIdentifier: opportunityidentifier
            }})
            console.log(opportunityResponse.data?.updateOpportunity?.msg);
          }
          setWorkloadError(false);
          addWorkloadKeyValuePairs({
            [workloadname]:
              response[0]?.workloadcreation?.data?.createWorkload?.WorkloadId ||
              "",
          });
          addRiskKeyValuePairs({
            [response[0]?.workloadcreation?.data?.createWorkload?.WorkloadId ||
            ""]: response[0]?.highriskcount || 0,
          });
        }
      }
      setDisabled(false);
      setActiveSpinner(false);
      setComplete(true);
      handleClose();
      if (failed === false) {
        setUnlockStep2(true);
        //Check if connected Account has Automate Plus and if targetAccount is Automate+
        if (
          targetAccountType === "AUTOMATED" &&
          connectedAutomatePlus === true
        ) {
          changeTab(1, technicalReviewArn, getFilteredConnectedAccounts());
        } else {
          changeTab(2, technicalReviewArn, getFilteredConnectedAccounts());
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Container maxWidth="lg" component={Paper}>
        <Box sx={{ flexGrow: 1, pb: 2 }}>
          <Grid
            container
            sx={{ pb: 2, pt: 2, pl: 2 }}
            spacing={2}
            alignItems="stretch"
            flexDirection={"column"}
          >
            <Typography
              component={"span"}
              variant="h6"
              color="text.primary"
              paragraph
            >
              AUTOMATE+ is integrated with the AWS Well-Architected Framework
              Tool to enable AWS Customers to Auto-Create, Auto-Fill,
              Auto-Remediate, and Auto-Update their Well-Architected Framework
              Review.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "inherit",
                p: 2,
                pl: 10,
                pr: 10,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 1: Create Workload
                </Typography>
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 2: Complete Best-Practice Recommendations
                </Typography>
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 3: Complete People, Policy & Tools Review
                </Typography>
              </Box>
              <Box>
                <Button
                  sx={{
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  href="https://aws.amazon.com/architecture/well-architected"
                  target="_blank"
                  variant="outlined"
                >
                  Learn more about AWS Well-Architected Framework
                </Button>
              </Box>
            </Box>
            <Grid item md={10}></Grid>
            <Grid item md={12}>
              {controlsUnknownAlert === true ? (
                <Alert severity="warning">
                  You have {unknownPercentage}% of your Security Hub controls in
                  an Unknown state, this may impact the number of HRI's reduced
                  when creating a workload.
                </Alert>
              ) : null}
              {workloadError === false && complete === true ? (
                <Alert severity="success">Workload successfully created</Alert>
              ) : null}
              {workloadError && complete === true ? (
                <Alert severity="error">{statusError}</Alert>
              ) : null}
            </Grid>
            <Grid item xs md={12}>
              <Typography
                component={"span"}
                variant="h6"
                color="text.primary"
                paragraph
              >
                Create a Well-Architected Workload in your AWS Account.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Card raised sx={{ height: "100%" }}>
                <CardHeader
                  action={
                    <CardActions>
                      <Checkbox disabled checked />
                    </CardActions>
                  }
                  title="AWS Well-Architected Framework"
                />
                <CardContent>
                  <Typography sx={{ mb: 1.5 }} color="text.primary">
                    Description
                  </Typography>
                  <Typography variant="body2">
                    The AWS Well-Architected Framework Lens provides a set of
                    foundational questions for you to consider for all of your
                    cloud architectures.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}>
              <Alert variant="outlined" severity="info">
                Please select the AWS Account that will create the AWS
                Well-Architected workload using the "All Accounts" drop-down in
                the top right corner of the screen.
              </Alert>
            </Grid>
            <Grid item md={12}>
              <Typography variant="body2">
                AWS Well-Architected Framework Reviews can attract AWS credits
                for eligible customers. Please enter the AWS Account that will
                receive the credit.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="awsCreditAccount"
                    placeholder="AWS Credit Account"
                    label="AWS Credit Account"
                    value={formik.values["awsCreditAccount"]}
                    error={
                      formik.touched["awsCreditAccount"] &&
                      Boolean(formik.errors["awsCreditAccount"])
                    }
                    helperText={
                      formik.touched["awsCreditAccount"] &&
                      formik.errors["awsCreditAccount"]
                        ? String(formik.errors["awsCreditAccount"])
                        : ""
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={12}>
              <Typography variant="body2">
                AWS Well-Architected Framework Reviews are conducted on a
                per-workload basis. Please choose the AWS account below that
                contains the workloads that are to be reviewed.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="aws-technical-review-account">
                    AWS Technical Review Account
                  </InputLabel>
                  <Select
                    required
                    id="awsTechnicalReviewAccount"
                    name="awsTechnicalReviewAccount"
                    value={technicalReviewArn}
                    label="AWS Technical Review Account"
                    onChange={handleTechnicalReviewAccount}
                  >
                    {accounts.map((a: any) => {
                      let splits = a.arn.split(":");
                      return (
                        <MenuItem
                          key={`${a.accessnickname} - ${splits[4]}`}
                          value={a.arn}
                        >{`${a.accessnickname} - ${splits[4]}`}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6}></Grid>
            {connectedAccountsDropDown.length > 1 ? (
              <>
                <Grid item md={12}>
                  <Typography variant="body2">
                    You have selected a AWS parent account which has multiple
                    AWS sub-accounts. Which AWS sub-account contains the
                    workload you want to review? Please select from the drop
                    down below.
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="aws-connected-account">
                        AWS Connected Account
                      </InputLabel>
                      <Select
                        required
                        id="awsConnectedAccount"
                        name="awsConnectedAccount"
                        value={accountIdRef}
                        label="AWS Connected Account"
                        onChange={handleConnectedAccount}
                      >
                        {connectedAccountsDropDown
                          .reduce(
                            (
                              acc: Array<string>,
                              a: ConnectedAccount,
                              index
                            ) => {
                              if (
                                a.accountIdRef &&
                                !acc.includes(a.accountIdRef)
                              ) {
                                acc.push(a.accountIdRef);
                              }
                              return acc;
                            },
                            []
                          )
                          .map((accountIdRef: string) => {
                            return (
                              <MenuItem
                                key={`${accountIdRef}`}
                                value={accountIdRef}
                              >{`${accountIdRef}`}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={6}></Grid>
              </>
            ) : null}
            <Grid item md={12}>
              <Typography variant="body2">
                Please select how many AWS Well-Architected Tool workload
                reports to generate?
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="number-of-workloads">
                    Total Workloads?
                  </InputLabel>
                  <Select
                    required
                    id="numberOfWorkloads"
                    name="numberOfWorkloads"
                    value={formik.values.numberOfWorkloads}
                    label="Total Workloads"
                    onChange={formik.handleChange}
                  >
                    <MenuItem key={1} value={1}>
                      1
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      2
                    </MenuItem>
                    <MenuItem key={3} value={3}>
                      3
                    </MenuItem>
                    <MenuItem key={4} value={4}>
                      4
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6}></Grid>
            {[...Array(formik.values.numberOfWorkloads || 0)].map((_, index) => (
              <React.Fragment key={index}>
                <Grid item md={6} sx={{ pb: 2 }}>
                  <TextField
                    fullWidth
                    id={`workloadName${index}`}
                    name={`workloadName${index}`}
                    placeholder="PROD-Workload Name"
                    label="Workload Name"
                    value={formik.values[`workloadName${index}`] || ""}
                    error={Boolean(formik.touched[`workloadName${index}`] && formik.errors[`workloadName${index}`])}
                    helperText={formik.touched[`workloadName${index}`] && formik.errors[`workloadName${index}`] 
                      ? String(formik.errors[`workloadName${index}`]) 
                      : ""}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{organisation}-</InputAdornment>,
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item md={6} sx={{ pb: 2 }}>
                  <TextField
                    fullWidth
                    id={`opportunityIdentifier${index}`}
                    name={`opportunityIdentifier${index}`}
                    placeholder="ACE Opportunity Identifier"
                    label="ACE Opportunity Identifier (Optional)"
                    value={formik.values[`opportunityIdentifier${index}`] || ""}
                    error={Boolean(formik.touched[`opportunityIdentifier${index}`] && formik.errors[`opportunityIdentifier${index}`])}
                    helperText={formik.touched[`opportunityIdentifier${index}`] && formik.errors[`opportunityIdentifier${index}`] 
                      ? String(formik.errors[`opportunityIdentifier${index}`]) 
                      : ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs md={4} justifyContent="flex-end">
              <LoadingButton
                id="wat-setup-btn"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={disabled}
                loading={activeSpinner}
                sx={{
                  width: 157,
                  height: 40,
                  bgcolor: "secondary.main",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "secondary.contrastText",
                  },
                }}
                loadingIndicator={
                  <CircularProgress color={"secondary"} size={22} />
                }
              >
                Create
              </LoadingButton>
            </Grid>
            <CardMedia
              component="img"
              sx={{ pt: 4 }}
              image={workloadimage}
              alt="6pillars WAT"
            />
          </Grid>
        </Box>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container>
              <Grid item xs md={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Please wait
                </Typography>
              </Grid>
              <Grid item xs md={12}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Please wait...Your Well-Architected Framework Review is being
                  created.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Typically, this should take a couple minutes if its multiple
                  workloads.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Feel free to press OK however please do not navigate away,
                  close or refresh your browser until you receive confirmation
                  on-screen that your workload has been successfully created as
                  this will impact the workload creation process.
                </Typography>
              </Grid>
              <Grid item xs md={8} sx={{ mt: 2 }}></Grid>
              <Grid item xs md={4} sx={{ mt: 2 }}>
                <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
                  <Button
                    onClick={() => handleClose()}
                    type="submit"
                    variant="contained"
                    sx={{
                      width: 157,
                      height: 40,
                      bgcolor: "secondary.main",
                      "&:hover": {
                        bgcolor: "secondary.main",
                        color: "secondary.contrastText",
                      },
                    }}
                  >
                    Ok
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </form>
  );
};

export default CreateWorkload;
