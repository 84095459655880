import React, { useState, FC } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useCreateOpportunityMutation,
  CountryCodeEnum,
  BusinessTitleEnum,
} from "../../generated";
import useAccount from "./../../hooks/useAccount";

// Example list of industries
const industryOptions = [
  'Aerospace',
  'Agriculture',
  'Automotive',
  'Computers and Electronics',
  'Consumer Goods',
  'Education',
  'Energy - Oil & Gas',
  'Energy - Power & Utilities',
  'Financial Services',
  'Gaming',
  'Government',
  'Healthcare',
  'Hospitality',
  'Life Sciences',
  'Manufacturing',
  'Marketing and Advertising',
  'Media and Entertainment',
  'Mining',
  'Non-Profit Organization',
  'Other',
  'Professional Services',
  'Real Estate and Construction',
  'Retail',
  'Software and Internet',
  'Telecommunications',
  'Transportation and Logistics',
  'Travel',
  'Wholesale and Distribution',
];

const businessTitleOptions = [
  'PartnerAccountManager',
  'OpportunityOwner'
];

// Validation schema using Yup
const validationSchema = Yup.object({
  companyName: Yup.string().required('Company name is required'),
  industryVertical: Yup.string().required('Industry vertical is required'),
  websiteURL: Yup.string()
    .url('Must be a valid URL')
    .required('Website URL is required'),
  countryCode: Yup.string().required('Country is required'),
  postalCode: Yup.string().required('Postal code is required'),
  customerEmail: Yup.string().email('Must be a valid email'),
  partnerEmail: Yup.string().email('Must be a valid email'),
  customerMobile: Yup.string(), 
  partnerMobile: Yup.string(),
});

const validateEnumValue = <T extends Record<string, string>>(
  value: string,
  enumObject: T
): T[keyof T] => {
  if (Object.values(enumObject).includes(value)) {
    return value as T[keyof T];
  }
  throw new Error(`Invalid value: ${value}`);
};

type AwsWellArchitectedLodgementModalProps = {
  open: boolean;
  onClose: () => void;
};

export const AwsWellArchitectedLodgementModal: FC<AwsWellArchitectedLodgementModalProps> = ({
  open,
  onClose
}) => {
  const { arn } = useAccount();

  // Track createOpportunity mutation and its response
  const [createOpportunityMutation, { loading }] = useCreateOpportunityMutation();

  // State to handle success popup and its displayed Opportunity ID
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [createdOpportunityId, setCreatedOpportunityId] = useState<string | null>(null);

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      companyName: '',
      industryVertical: '',
      websiteURL: '',
      countryCode: '',
      postalCode: '',
      customerFirstName: '',
      customerLastName: '',
      customerTitle: '',
      customerEmail: '',
      customerMobile: '',
      partnerFirstName: '',
      partnerLastName: '',
      partnerTitle: '',
      partnerEmail: '',
      partnerMobile: '',
    },
    validationSchema,
    onSubmit: (values) => {
      console.log('Form Submitted', values);
      alert('Form submitted! Check the console for the form values.');
    },
  });

  // Handler for the "Submit" button to call your GraphQL mutation
  const handleSubmit = async () => {
    const isValid = await formik.validateForm();
    if (Object.keys(isValid).length === 0) {
      const input: any = {
        Customer: {
          Account: {
            CompanyName: formik.values.companyName,
            Industry: formik.values.industryVertical,
            WebsiteUrl: formik.values.websiteURL,
            Address: {
              CountryCode: formik.values.countryCode
                ? validateEnumValue(formik.values.countryCode, CountryCodeEnum)
                : null,
              PostalCode: formik.values.postalCode,
            },
          }
        }
      };

      if (formik.values.customerFirstName) {
        input.Customer.Contacts = [
          {
            FirstName: formik.values.customerFirstName,
            LastName: formik.values.customerLastName,
            BusinessTitle: formik.values.customerTitle
              ? validateEnumValue(formik.values.customerTitle, BusinessTitleEnum)
              : null,
            Email: formik.values.customerEmail,
            Phone: formik.values.customerMobile,
          }
        ];
      }

      if (formik.values.partnerFirstName) {
        input.OpportunityTeam = [
          {
            FirstName: formik.values.partnerFirstName,
            LastName: formik.values.partnerLastName,
            BusinessTitle: formik.values.partnerTitle
              ? validateEnumValue(formik.values.partnerTitle, BusinessTitleEnum)
              : null,
            Email: formik.values.partnerEmail,
            Phone: formik.values.partnerMobile,
          }
        ];
      }

      console.log("Input Payload", input);

      try {
        const { data } = await createOpportunityMutation({
          context: {
            apiName: "well_architected"
          },
          variables: {
            arn,
            input,
          }
        });
        // If successful, extract the opportunityId and show a popup
        if (data?.createOpportunity?.opportunityId) {
          setCreatedOpportunityId(data.createOpportunity.opportunityId);
          setSuccessPopupOpen(true);
        }
      } catch (err) {
        console.error("Error creating opportunity: ", err);
      }
    } else {
      formik.setTouched({
        companyName: true,
        industryVertical: true,
        websiteURL: true,
        countryCode: true,
        postalCode: true,
        customerEmail: true,
        partnerEmail: true
      });
    }
  };

  return (
    <>
      {/* Main dialog remains open on success (no onClose() in handleSubmit) */}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ pb: 0 }}>
          AWS Well-Architected - ACE Opportunity Creation
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            {/* ========== Section: Basic Company & Opportunity Info ========== */}
            <Paper elevation={0} sx={{ p: 2, mb: 3, border: '1px solid #ddd' }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                Company & Opportunity Information
              </Typography>
              <Grid container spacing={2}>
                {/* Company Name (Required) */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="companyName"
                    name="companyName"
                    label="Company Name *"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                  />
                </Grid>
                {/* Industry (Required) */}
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={formik.touched.industryVertical && Boolean(formik.errors.industryVertical)}
                  >
                    <InputLabel id="industryVertical-label">Industry *</InputLabel>
                    <Select
                      labelId="industryVertical-label"
                      id="industryVertical"
                      name="industryVertical"
                      label="Industry *"
                      value={formik.values.industryVertical}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="">
                        <em>-- Select an industry --</em>
                      </MenuItem>
                      {industryOptions.map((option, idx) => (
                        <MenuItem value={option} key={idx}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.industryVertical && formik.errors.industryVertical}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* Website URL (Required) */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="websiteURL"
                    name="websiteURL"
                    label="Website URL *"
                    placeholder="https://www.example.com"
                    value={formik.values.websiteURL}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.websiteURL && Boolean(formik.errors.websiteURL)}
                    helperText={formik.touched.websiteURL && formik.errors.websiteURL}
                  />
                </Grid>
                {/* Country Code (Required) */}
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                  >
                    <InputLabel id="countryCode-label">Country *</InputLabel>
                    <Select
                      labelId="countryCode-label"
                      id="countryCode"
                      name="countryCode"
                      label="Country *"
                      value={formik.values.countryCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="">
                        <em>-- Select your country --</em>
                      </MenuItem>
                      {Object.values(CountryCodeEnum).map((countryOption, idx) => (
                        <MenuItem value={countryOption} key={idx}>
                          {countryOption}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.countryCode && formik.errors.countryCode}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* Postal Code (Required) */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    label="Postal Code *"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                  />
                </Grid>
              </Grid>
            </Paper>
            {/* ========== Section: Customer Details (Optional) ========== */}
            <Paper elevation={0} sx={{ p: 2, mb: 3, border: '1px solid #ddd' }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                Customer Details (Optional)
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerFirstName"
                    name="customerFirstName"
                    label="First Name"
                    value={formik.values.customerFirstName}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerLastName"
                    name="customerLastName"
                    label="Last Name"
                    value={formik.values.customerLastName}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={formik.touched.customerTitle && Boolean(formik.errors.customerTitle)}
                  >
                    <InputLabel id="customerTitle-label">Title / Role</InputLabel>
                    <Select
                      labelId="customerTitle-label"
                      id="customerTitle"
                      name="customerTitle"
                      label="Title / Role"
                      value={formik.values.customerTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="">
                        <em>-- Select title/role --</em>
                      </MenuItem>
                      {businessTitleOptions.map((option) => (
                        <MenuItem value={option} key={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.customerTitle && formik.errors.customerTitle}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerEmail"
                    name="customerEmail"
                    label="Email Address"
                    value={formik.values.customerEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.customerEmail && Boolean(formik.errors.customerEmail)}
                    helperText={formik.touched.customerEmail && formik.errors.customerEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="customerMobile"
                    name="customerMobile"
                    label="Mobile Number"
                    value={formik.values.customerMobile}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>
            {/* ========== Section: Partner Details (Optional) ========== */}
            <Paper elevation={0} sx={{ p: 2, mb: 3, border: '1px solid #ddd' }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                Partner Details (Optional)
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerFirstName"
                    name="partnerFirstName"
                    label="First Name"
                    value={formik.values.partnerFirstName}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerLastName"
                    name="partnerLastName"
                    label="Last Name"
                    value={formik.values.partnerLastName}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={formik.touched.partnerTitle && Boolean(formik.errors.partnerTitle)}
                  >
                    <InputLabel id="partnerTitle-label">Title / Role</InputLabel>
                    <Select
                      labelId="partnerTitle-label"
                      id="partnerTitle"
                      name="partnerTitle"
                      label="Title / Role"
                      value={formik.values.partnerTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="">
                        <em>-- Select title/role --</em>
                      </MenuItem>
                      {businessTitleOptions.map((option) => (
                        <MenuItem value={option} key={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.partnerTitle && formik.errors.partnerTitle}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerEmail"
                    name="partnerEmail"
                    label="Email Address"
                    value={formik.values.partnerEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.partnerEmail && Boolean(formik.errors.partnerEmail)}
                    helperText={formik.touched.partnerEmail && formik.errors.partnerEmail}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="partnerMobile"
                    name="partnerMobile"
                    label="Mobile Number"
                    value={formik.values.partnerMobile}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={loading}
            loading={loading}
            loadingIndicator={
              <CircularProgress color={"secondary"} size={22} />
            }
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Success Popup Dialog */}
      <Dialog
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
      >
        <DialogTitle>Opportunity Created</DialogTitle>
        <DialogContent>
          {createdOpportunityId ? (
            <Typography>
              Your new Opportunity ID is: <strong>{createdOpportunityId}</strong>
            </Typography>
          ) : (
            <Typography>Opportunity created successfully!</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessPopupOpen(false)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AwsWellArchitectedLodgementModal;