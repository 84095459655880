import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import { Box, Chip } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

interface CostImpactIconProps {
  costImpact: string;
}

const CostImpactIcon = ({ costImpact }: CostImpactIconProps) => {
  const costImpactMap: any = {
    Negligible: (
      <Tooltip title="Cost Impplication">
        {/* <MonetizationOnIcon sx={{ color: "red" }} /> */}
        <Chip
          label={
            <Box sx={{ display: "flex" }}>
              <AttachMoneyOutlinedIcon
                sx={{ color: "white" }}
                fontSize="inherit"
              />
            </Box>
          }
          size="small"
          sx={{ backgroundColor: "#e90000", color: "white" }}
        />
      </Tooltip>
    ),
    Minimal: (
      <Tooltip title="Minimal">
        <Chip
          label={
            <Box sx={{ display: "flex" }}>
              <AttachMoneyOutlinedIcon
                sx={{ color: "white" }}
                fontSize="inherit"
              />
              <AttachMoneyOutlinedIcon
                sx={{ color: "white" }}
                fontSize="inherit"
              />
            </Box>
          }
          size="small"
          sx={{ backgroundColor: "#e90000", color: "white" }}
        />
      </Tooltip>
    ),
    Moderate: (
      <Tooltip title="Moderate">
        <Chip
          label={
            <Box sx={{ display: "flex" }}>
              <AttachMoneyOutlinedIcon
                sx={{ color: "white" }}
                fontSize="inherit"
              />
              <AttachMoneyOutlinedIcon
                sx={{ color: "white" }}
                fontSize="inherit"
              />
              <AttachMoneyOutlinedIcon
                sx={{ color: "white" }}
                fontSize="inherit"
              />
            </Box>
          }
          size="small"
          sx={{ backgroundColor: "#e90000", color: "white" }}
        />
      </Tooltip>
    ),
  };
  return costImpactMap[costImpact] || "";
};

export default CostImpactIcon;
