import { useState } from "react";
import Heading from "../common/Heading";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import workloadimage from "./../../assets/6pillars_wat_graphic_new_color.png";
import { useNavigate } from "react-router-dom";
import { AwsWellArchitectedLodgementModal } from "../partner/PartnerAutomation";

export default function WatMainPage() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Container component="main">
      <Container maxWidth="lg">
        <AwsWellArchitectedLodgementModal
          open={openModal}
          onClose={handleClose}
        />
        <Heading heading={"AWS Well-Architected Framework Review"} />
        <Typography variant="h4" color="primary.main" paragraph>
          Auto-Create / Auto-Fill / Auto-Remediate
        </Typography>
      </Container>
      <Box sx={{ width: "100%", pt: 2 }}>
        <Container maxWidth="lg" component={Paper}>
          <Grid
            container
            sx={{ pb: 2, pt: 2, pl: 2 }}
            spacing={2}
            alignItems="stretch"
            flexDirection={"column"}
          >
            <Typography
              component={"span"}
              variant="h6"
              color="text.primary"
              paragraph
            >
              AUTOMATE+ is integrated with the AWS Well-Architected Framework
              Tool to enable AWS Customers to Auto-Create, Auto-Fill,
              Auto-Remediate, and Auto-Update their Well-Architected Framework
              Review.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "inherit",
                p: 2,
                pl: 10,
                pr: 10,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 1: Create Workload
                </Typography>
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 2: Complete Best-Practice Recommendations
                </Typography>
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="text.secondary"
                  paragraph
                  sx={{ mb: 0 }}
                >
                  STEP 3: Complete People, Policy & Tools Review
                </Typography>
              </Box>
              <Box>
                <Stack spacing={2}>
                  <Button
                    sx={{
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                    href="https://aws.amazon.com/architecture/well-architected"
                    target="_blank"
                    variant="outlined"
                  >
                    Learn more about AWS Well-Architected Framework
                  </Button>
                  <Button
                    sx={{
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                    variant="outlined"
                    onClick={handleOpen}
                  >
                    Create ACE Opportunity in 6pillar Partner Central.
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid xs={12} justifyContent="center" alignItems="center">
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6">
                  Please select how you want to define your AWS Well-Architected
                  workload.
                </Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => {
                    navigate("/watiac");
                  }}
                  type="submit"
                  variant="outlined"
                  sx={{
                    width: 250,
                    height: 40,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  IaC
                </Button>
                <Button
                  onClick={() => {
                    navigate("/watinfra");
                  }}
                  type="submit"
                  variant="contained"
                  sx={{
                    width: 250,
                    height: 40,
                    bgcolor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    },
                  }}
                >
                  Cloud Infrastructure
                </Button>
              </Stack>
            </Grid>
            <CardMedia
              component="img"
              sx={{ pt: 4, pb: 4 }}
              image={workloadimage}
              alt="6pillars WAT"
            />
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}
